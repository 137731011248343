export const routes = {
  home: "/",
  blog: "/blog",
  contact_us: "/contact-us",
 
};

export const contact = {
  phone: "09079313294",
  whatsapp:"https://Wa.me/+2349079313294"
};

